// let baseApi = 'http://edu-course-backend.it266.com'
// let baseApi = 'https://www.itnova.top'
// let baseApi = 'http://edu-course-backend.it266.com'
// let baseApi = 'http://localhost:8080/web/index.php'
let baseApi = 'https://www.daimaku.net'

let baseApiLocal = window.localStorage.getItem("baseApi")

if (baseApiLocal) {
    baseApi = baseApiLocal
}


let artifactId = 'daimaku-v2'

let baseUrlOj = 'https://www.daimaku.net/oj'

export {baseApi, artifactId, baseUrlOj}
