import React, {useState} from 'react';
import './info.less'
import {Button, Input, Select} from "antd";
import common from "../../utils/common";
import {connect} from "react-redux";

function PassportInfo(props) {
    let {redirect} = props

    const [info, setInfo] = useState({
        nickname: '',
        school: '',
        education: '',
        major: '',
        year: '',
        realname: '',
    })

    //拉取当前用户的基础信息
    let userInfo = () => {
        //拉取当前用户的基础信息
        common.ajax("get", "/api/user/whoami", {}, {
            useToken: true,
            contentType: 'application/x-www-form-urlencoded'
        }).then((data) => {

            let user = {
                id: data.id,
                nickname: data.nickname,
                avatar: data.avatar,
                avatar_url: data.avatar_url,
            }

            props.changeUser(user)
            redirect()
        })
    }
    const save = () => {
        if (info.nickname.length === 0) {
            common.toast("请输入昵称")
            return
        }
        if (info.school.length === 0) {
            common.toast("请输入school")
            return
        }
        if (info.year.length === 0) {
            common.toast("请选择入学年份")
            return
        }
        if (info.education.length === 0) {
            common.toast("请输入学历")
            return
        }
        if (info.major.length === 0) {
            common.toast("请输入专业")
            return
        }
        if (info.realname.length === 0) {
            common.toast("请输入真实姓名")
            return
        }

        common.loadingStart()
        common.ajax('post', '/api/user/saveInfo', {
            ...info,
            accessToken: window.localStorage.getItem('accessToken'),
            device_code: common.deviceCode(),
            device_info: JSON.stringify(common.deviceInfo()),
        }, {
            useToken: false,
            contentType: 'application/x-www-form-urlencoded'
        }).then(res => {
            //保存Token
            common.setToken(res.token)

            //拉取当前用户的基础信息
            userInfo()
        }).finally(common.loadingStop)
    }

    const getYears = () => {
        const y = new Date().getFullYear()

        let arr = []
        for (let i = 0; i <= 6; i++) {
            arr.push(y - i)
        }
        return arr
    }

    return (
        <div className={'PassportInfo'}>
            <div className={'title'}>完善资料</div>
            <div className={'subtitle'}>以下个人信息仅昵称对外公开显示</div>
            <div className={'row'}>
                <div className={'label'}>
                    昵称
                    <span></span>
                </div>
                <div className={'center'}>
                    <Input size={"large"} value={info.nickname} placeholder={"请输入昵称"} onChange={e => {
                        setInfo({...info, nickname: e.target.value})
                    }}/>
                </div>
            </div>


            <div className={'row'}>
                <div className={'label'}>
                    学校
                </div>
                <div className={'center'}>
                    <Input size={"large"} placeholder={"请输入学校"} value={info.school} onChange={e => {
                        setInfo({...info, school: e.target.value})
                    }}/>
                </div>
            </div>

            <div className={'row'}>
                <div className={'label'}>
                    入学年份
                </div>
                <div className={'center'}>
                    <Select value={info.year} style={{width: '100%'}} onChange={v => {
                        setInfo({...info, year: v})
                    }}>
                        <Select.Option value={''}><span style={{color: '#ccc'}}>请选择入学年份</span></Select.Option>
                        {getYears().map(item => {
                            return <Select.Option value={item}>{item}</Select.Option>
                        })}
                        <Select.Option value={0}>更早</Select.Option>
                    </Select>
                </div>
            </div>

            <div className={'row'}>
                <div className={'label'}>
                    学历
                </div>
                <div className={'center'}>
                    <Select value={info.education} style={{width: '100%'}} onChange={v => {
                        setInfo({...info, education: v})
                    }}>
                        <Select.Option value={''}><span style={{color: '#ccc'}}>请选择学历</span></Select.Option>
                        <Select.Option value={'博士'}>博士</Select.Option>
                        <Select.Option value={'硕士'}>硕士</Select.Option>
                        <Select.Option value={'本科'}>本科</Select.Option>
                        <Select.Option value={'专科'}>专科</Select.Option>
                        <Select.Option value={'其他'}>其他</Select.Option>
                    </Select>
                </div>
            </div>

            <div className={'row'}>
                <div className={'label'}>
                    专业
                </div>
                <div className={'center'}>
                    <Input size={"large"} placeholder={"请输入专业"} value={info.major} onChange={e => {
                        setInfo({...info, major: e.target.value})
                    }}/>
                </div>
            </div>
            <div className={'row'}>
                <div className={'label'}>
                    姓名
                </div>
                <div className={'center'}>
                    <Input size={"large"} placeholder={"请输入真实姓名"} value={info.realname} onChange={e => {
                        setInfo({...info, realname: e.target.value})
                    }}/>
                </div>
            </div>
            <div className={'row'}>
                <Button block type={'primary'} onClick={save}>保存</Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        tenant: state.tenant,
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PassportInfo);

