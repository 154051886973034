import React, {useEffect, useRef, useState} from 'react';
import common from "../../utils/common";
import {QRCode} from "antd";

/**
 * 微信扫码关注公众号后，自动登录
 */
function Wechat(props) {

    const {getUserInfo, setScanKey} = props

    const ignore = useRef(false)                  // 组件是否被销毁

    let [qrcodeUrl, setQrcodeUrl] = useState('')   // 二维码图片url
    let [isLost, setIsLost] = useState(false)      // 二维码是否失效  true: 已失效, false: 未失效

    let scanTimer = useRef(null)     // 扫码检查
    let clearTimer = useRef(null)    // 二维码过期

    // 获取二维码
    let getQr = () => {
        setQrcodeUrl('')
        setIsLost(false)
        common.ajax('post', 'https://www.it266.com/api/wechat/subscribe-qrcode/1001', {}, {useToken: false}).then((res) => {

            // 如果组件已销毁，不需要后续动作了
            if (ignore.current) {
                return
            }

            setQrcodeUrl(res.image_url)

            // 不断检查用户是否已扫码
            getScan(res.key)

            // 2分钟后，显示二维码已过期，停止检查
            clearTimer.current = setTimeout(() => {
                clearTimeout(scanTimer.current)
                clearTimeout(clearTimer.current)
                setIsLost(true)
            }, 60 * 2 * 1000)

        })
    }

    // 不断检查用户是否已扫码
    let getScan = (key) => {

        scanTimer.current = setTimeout(() => {

            common.ajax('get', '/api/token/createFromScan', {
                key: key,
                device_code: common.deviceCode(),
            }, {
                useToken: false,
                displayError: false
            }).then((result) => {

                //保存Token
                common.setToken(result.token)

                clearTimeout(scanTimer.current)
                clearTimeout(clearTimer.current)

                // 拉取当前用户的信息
                getUserInfo()

            }).catch((err) => {

                if (ignore.current) {
                    return
                }

                if (err.code !== 'KEY_INVALID') {
                    if (err.code === 'UNREGISTERED') {

                        // 此微信未绑定帐号，需要绑定帐号
                        // 将扫码的key返回上层业务，用于绑定帐号
                        setScanKey(key)

                        clearTimeout(scanTimer.current)
                        clearTimeout(clearTimer.current)
                    }
                } else {
                    getScan(key)
                }
            })

        }, 1000)
    }

    useEffect(() => {

        getQr()

        return () => {
            ignore.current = true
            clearTimeout(scanTimer.current)
            clearTimeout(clearTimer.current)
        }
    }, [])

    return (
        <>
            <div className="title we weScan">微信扫码登录</div>
            <div className={'wechat-tip'}>
                <div>请使用微信扫描二维码</div>
                <div>关注公众号</div>
                <div>即可登录成功哦～</div>
            </div>
            <div className="img-div">

                {/*不为空，未失效*/}
                {(qrcodeUrl !== '' && !isLost) ?
                    <img className="img" src={qrcodeUrl} alt=""/> : ''}

                {/*已失效*/}
                {isLost ?
                    <QRCode value={'expired'} status="expired" onRefresh={() => getQr()}/> : ''}

                {/* 为空时 loading */}
                {qrcodeUrl === '' ? (
                    <div className="mask mask-load">
                        <QRCode value={'loading'} status="loading"/>
                    </div>
                ) : ''}


            </div>
        </>
    );
}

export default Wechat;
