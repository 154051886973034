import React, {useState} from 'react';
import common from "../../utils/common";
import {Form, Input, Tooltip, Row, Col, Button, Modal,} from 'antd';
import {
    LockOutlined, QuestionCircleOutlined,
    MobileOutlined, FontColorsOutlined,
    MailOutlined, TranslationOutlined
} from '@ant-design/icons';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import './register.less'


function Register(props) {
    let {scanKey, setShow, redirect} = props

    let [mobileValue, setMobileValue] = useState('')
    let [verificationKey, setVerificationKey] = useState('')
    let [imgCaptchaUrl, setImgCaptchaUrl] = useState('')
    let [imgCaptchaKey, setImgCaptchaKey] = useState('')
    let [imgCaptchaCode, setImgCaptchaCode] = useState('')

    const [form] = Form.useForm();

    // 点击注册
    let handleSubmit = (values) => {

        let inviteCode = window.localStorage.getItem('invite')

        if (verificationKey == null || verificationKey.length === 0) {
            common.alert("手机验证码无效")
            return
        }

        let params = {
            mobile: values.mobile,
            verificationKey: verificationKey,
            verificationCode: values.captcha,
            password: values.password,
            rePassword: values.password,
            nickname: values.nickname,
            name: values.name,
            scanKey: scanKey,
            inviteCode: inviteCode ? inviteCode : '',
            tenant_id: props.tenant.id,
        };

        common.loadingStart()
        common.ajax("post", "/api/user/register", {
            'data': JSON.stringify(params)
        }, {useToken: false, contentType: 'application/x-www-form-urlencoded'}).then((data) => {

            //保存Token
            common.setToken(data.token)

            //拉取当前用户的基础信息
            userInfo()

        }).finally(() => {
            common.loadingStop()
        })
    }

    //拉取当前用户的基础信息
    let userInfo = () => {
        //拉取当前用户的基础信息
        common.ajax("get", "/api/user/whoami", {}, {
            useToken: true,
            contentType: 'application/x-www-form-urlencoded'
        }).then((data) => {

            let user = {
                id: data.id,
                nickname: data.nickname,
                avatar: data.avatar,
                avatar_url: data.avatar_url,
            }

            props.changeUser(user)

            common.message.success("注册成功")

            redirect()

        })
    }

    // 发送验证码
    let sendSms = (e) => {

        setImgCaptchaUrl('')

        let mobile = mobileValue
        let captchaKey = imgCaptchaKey
        let captchaCode = imgCaptchaCode

        common.loadingStart()
        common.ajax("post", "/api/user/sms", {
            mobile, captchaKey, captchaCode
        }, {useToken: false, displayError: false, contentType: 'application/x-www-form-urlencoded'}).then((data) => {
            setVerificationKey(data.verificationKey)
            common.toast(data.message)

        }).catch((res) => {
            if (res.code === "INVALID_CAPTCHA") {
                common.ajax("get", '/api/image/captcha', {}, {useToken: false}).then((result) => {
                    setImgCaptchaKey(result.key)
                    setImgCaptchaUrl(result.url)
                })

            } else {
                common.alert(res.data)
            }

        }).finally(common.loadingStop)
    }

    // 表单样式
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    // 验证码弹框样式
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <div className={'Register'}>
            <div className="myform">
                <div className="title">
                    请注册
                </div>
                <Form {...formItemLayout} form={form} onFinish={handleSubmit}
                      className="login-form">
                    <Form.Item label="手机号" name="mobile"
                               rules={[{required: true, message: '请输入手机号'}]}>
                        <Input
                            className={'txt'}
                            prefix={<MobileOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder="手机号"
                            onChange={(e) => {
                                setMobileValue(e.target.value)
                            }}
                        />

                    </Form.Item>

                    <Form.Item label="验证码" name="captcha"
                               rules={[{required: true, message: '请输入验证码'}]}>
                        <Row gutter={8}>
                            <Col span={18}>
                                <Input
                                    placeholder={'短信验证码'}
                                    prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    className={'txt'}/>
                            </Col>
                            <Col span={6}>
                                <Button className={'send'} type={'primary'} onClick={sendSms}>发送</Button>
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item label="密码" name="password" hasFeedback
                               rules={[
                                   {required: true, message: '请输入密码'},
                               ]}>
                        <Input.Password
                            className={'txt'}

                            prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                            type="password"
                            placeholder="登录密码"
                        />
                    </Form.Item>

                    <Form.Item name="nickname"
                               rules={[{required: true, message: '请输入昵称', whitespace: true}]}
                               label={<span>昵称</span>}>
                        <Input prefix={<FontColorsOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                               className={'txt'} placeholder="请输入昵称"/>
                    </Form.Item>

                    <Form.Item name="name" label={<span>姓名&nbsp;
                        <Tooltip title="仅对老师可见，不对外显示">
                                <QuestionCircleOutlined style={{color: '#999'}}/>
                        </Tooltip></span>}
                               rules={[{required: true, message: '请输入真实姓名', whitespace: true}]}>

                        <Input prefix={<TranslationOutlined style={{color: 'rgba(0,0,0,.25)'}}/>} className={'txt'}
                               placeholder={'真实姓名'}
                        />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button className={'btn'} type="primary" htmlType="submit">
                            注册
                        </Button>
                        <Button className={'btn'} type="link" onClick={() => {
                            setShow('login')
                        }}>我有账号</Button>
                    </Form.Item>
                </Form>

                <Modal
                    open={imgCaptchaUrl}
                    footer={null}
                    onCancel={() => {
                        setImgCaptchaUrl('')
                    }}
                >
                    <div>
                        <img src={imgCaptchaUrl} alt=""/>

                        <Form>
                            <Form.Item
                                label={
                                    <span>请输入图片中的数字</span>
                                }
                            >
                                <Input placeholder={""} onChange={(e) => {
                                    setImgCaptchaCode(e.target.value)
                                }}/>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" onClick={sendSms}>确定</Button>
                            </Form.Item>
                        </Form>


                    </div>
                </Modal>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        tenant: state.tenant
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register))
